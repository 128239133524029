var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-page mt-5 py-5"},[_c('datatable',{attrs:{"searchable":"","path":"/transactions","headers":[
        {text: 'ID', value: 'id'},
        {text: 'Beschreibung', value: 'description'},
        {text: 'Betrag', value: 'amount'},
        {text: 'Datum', value: 'created_at'}
    ]},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_c('span',{style:({ color: (item.amount < 0 ? 'red' : undefined) })},[_vm._v(_vm._s(item.amount.toFixed(2).replace('.', ','))+" €")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }